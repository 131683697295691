import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    FacebookLogo: () => import(/* webpackChunkName: "footer-icon" */ '@/components/icons/FacebookLogo'),
    InstagramLogo: () => import(/* webpackChunkName: "footer-icon" */ '@/components/icons/InstagramLogo'),
    LinkedInLogo: () => import(/* webpackChunkName: "footer-icon" */ '@/components/icons/LinkedInLogo'),
    YoutubeLogo: () => import(/* webpackChunkName: "footer-icon" */ '@/components/icons/YoutubeLogo'),
    Chevron: () => import(/* webpackChunkName: "footer-icon" */ '@/components/icons/Chevron'),
    Location: () => import(/* webpackChunkName: "footer-icon" */ '@/components/icons/Location'),
    Whatsapp: () => import(/* webpackChunkName: "footer-icon" */ '@/components/icons/Whatsapp'),
    Email: () => import(/* webpackChunkName: "footer-icon" */ '@/components/icons/Email'),
    G2Academy: () => import(/* webpackChunkName: "footer-icon" */ '@/components/icons/G2Academy'),
    ImageItem: () => import(/* webpackChunkName: "image" */ '@/components/image/ImageItem')
  },
  computed: {
    ...mapGetters('common', ['adminGeneral', 'emailGeneral', 'locationLink'])
  },
  methods: {
    ...mapActions('common', ['sendWhatsApp']),
    toLocation() {
      window.open(this.locationLink)
    },
    toWhatsApp() {
      this.sendWhatsApp({ number: this.adminGeneral })
    },
    toEmail() {
      window.location.href = `mailto:${this.emailGeneral}`
    },
    getYear() {
      return new Date().getFullYear()
    }
  }
}
